export const types = {
  // AUTH
  login: '[AUTH] Login',
  logout: '[AUTH] Logout',

  isAuth: '[AUTH] is Authenticated',
  noAuth: '[AUTH] isnt Authenticated',

  retryRefreshToken: '[AUTH] RetryRefreshToken',
  resetRefreshToken: '[AUTH] ResetRefreshToken',

  // UI
  openSidebar: '[UI] OpenSidebar',
  closeSidebar: '[UI] CloseSidebar',

  invalidForm: '[UI] InvalidForm',
  validForm: '[UI] ValidForm',

  loading: '[UI] Page loading',
  loaded: '[UI] Page loaded',

  selectedRow: '[UI] SelectedRow',
  cleanRow: '[UI] cleanRow',

  selectedPhishingCampaign: '[UI] PhishingCampaignSelectedRow',
  cleanPhishingCampaign: '[UI] CleanPhishingCampaign',

  selectedPhishingSenderDetails: '[UI] PhishingSenderDetailsSelectedRow',
  cleanPhishingSenderDetails: '[UI] CleanPhishingSenderDetails',

  selectedCampaign: '[UI] CampaignSelectedRow',
  cleanCampaignRow: '[UI] CleanCampaignRow',

  selectedCampaignControlSource: '[UI] CampaignSelectedControlSource',
  cleanCampaignControlSourceRow: '[UI] CleanCampaignSource',

  selectedScenario: '[UI] ScenarioSelectedRow',
  cleanScenarioRow: '[UI] CleanScenarioRow',

  selectedCampaignScenario: '[UI] selectedCampaignScenario',
  cleanCampaignScenario: '[UI] cleanCampaignScenario',

  selectedCampaignReport: '[UI] selectedCampaignReport',
  cleanCampaignReport: '[UI] cleanCampaignReport',

  selectedCampaignRecord: '[UI] selectedCampaignRecord',
  cleanCampaignRecord: '[UI] cleanCampaignRecord',

  selectedRecordView: '[UI] selectedRecordView',
  cleanRecordView: '[UI] cleanRecordView',

  selectedMachine: '[UI] selectedMachine',
  cleanMachine: '[UI] cleanMachine',

  //* TESTING
  selectedScenarioTester: '[UI] selectedScenarioTester',
  cleanScenarioTester: '[UI] cleanScenarioTester',

  //* EXECUTION
  selectedPhishingExecution: '[UI] selectedPhishingExecution',
  cleanPhishingExecution: '[UI] cleanPhishingExecution',

  selectedJob: '[UI] selectedJob',
  selectedMultiJobs: '[UI] selectedMultiJobs',
  cleanJob: '[UI] cleanJob',
  cleanMultiJobs: '[UI] cleanMultiJob',

  //* CATALOG
  selectedUser: '[UI] selectedUser',
  cleanUser: '[UI] cleanUser',

  selectedClient: '[UI] selectedClient',
  cleanClient: '[UI] cleanClient',

  selectedControlType: '[UI] selectedControlType',
  cleanControlType: '[UI] cleanControlType',

  selectedIOA: '[UI] selectedIOA',
  cleanIOA: '[UI] cleanIOA',

  selectedIOC: '[UI] selectedIOC',
  cleanIOC: '[UI] cleanIOC',

  selectedIndustry: '[UI] selectedIndustry',
  cleanIndustry: '[UI] cleanIndustry',

  selectedMittreTechnique: '[UI] selectedMittreTechnique',
  cleanMittreTechnique: '[UI] cleanMittreTechnique',

  selectedTemplate: '[UI] selectedTemplate',
  cleanTemplate: '[UI] cleanTemplate',
}
