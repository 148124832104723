import React from 'react'

import PrivateRoutes from './PrivateRoutes'

import { DefaultRoutes } from '../constants/routes/routes'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

function AuthGuard() {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const location = useLocation()

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`../${DefaultRoutes.public.LOGIN}`}
        replace
        state={{ from: location }}
      />
    )
  }

  return isAuthenticated && <PrivateRoutes />
}

export default AuthGuard
