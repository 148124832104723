import React from 'react'
import Card from '../components/Card'
import PieChartComponent from '../components/charts/BarChartComponent'
import { useQuery } from 'react-query'
import { PrivateAxios } from '../api/auth/PrivateAxios'
// import LineChartComponent from '../components/charts/LineChart'

function Dashboard() {
  const { isLoading, data, isFetching } = useQuery(
    [''],
    async () => {
      try {
        const response = await PrivateAxios.get(
          `/api/v1/machines/test-graph/bcdef9b0-8500-4e7f-b6e2-d8eb767945fa`
        )

        return response
      } catch (error) {
        console.log(error)
      }
    },
    {
      cacheTime: 0,
    }
  )

  const bars = [
    {
      key: 'BYPASS',
      color: '#E82F49',
    },
    {
      key: 'PARTIAL BYPASS',
      color: '#F8BE00',
    },
    {
      key: 'GOOD',
      color: '#3DB065',
    },
  ]

  if (isLoading || isFetching) {
    return <div>Loading...</div>
  }
  return (
    <div className="m-4 my-4 ">
      <div className="flex flex-wrap gap-4 justify-center py-8">
        <Card />
        {!!data?.data?.PHISHING && (
          <PieChartComponent
            xKey="phase"
            bars={bars}
            data={Object.keys(data?.data).map((phase) => ({
              phase,
              ...data.data[phase],
            }))}
            width="80%"
          />
        )}
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  )
}

export default Dashboard
