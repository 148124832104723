import axios from 'axios'
import { store } from '../../store/store'
import {
  isAuthenticated,
  login,
  noAuthenticated,
  resetRefreshToken,
  retryRefreshToken,
} from '../../actions/authActions'
import { formatUsername, getTokenBody } from '../../utils/utils'
import { toast } from 'react-toastify'

export const PrivateAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_IP}`,
  timeout: 19000,
  withCredentials: true,
  headers: {
    common: {
      Authorization: `Bearer ${
        !!localStorage.getItem('token')
          ? localStorage.getItem('token')
          : 'notokenfound'
      }`,
    },
  },
})

PrivateAxios.interceptors.response.use(undefined, async (error) => {
  const originalRequest = error.config

  // Verificar si el error es de autorización y si ya se intentó refrescar el token
  if (
    error.response &&
    error.response.status === 401 &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true // Marcar como intentado

    const has_tried = store?.getState()?.auth?.hasTryRefreshToken ?? false

    if (has_tried) {
      store.dispatch(resetRefreshToken())
      error.data = 'No permission'
      toast.error(
        'Ups! Parece que no tienes permisos para realizar esta acción',
        { toastId: 'no-permission' }
      )
      return Promise.reject(error)
    }

    try {
      const refreshResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_IP}/api/v1/auth/refresh-token`,
        {
          withCredentials: true,
        }
      )

      if (refreshResponse.status !== 200) throw new Error('Token invalid')

      const newAccessToken = refreshResponse.data?.access_token
      localStorage.setItem('token', newAccessToken)

      // Actualizar el header Authorization y el store con el nuevo token
      PrivateAxios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${newAccessToken}`

      const { sub, scopes } = getTokenBody()

      store.dispatch(login({ username: formatUsername(sub), role: scopes[0] }))
      store.dispatch(resetRefreshToken())
      store.dispatch(isAuthenticated())

      // Retornar la solicitud original con el nuevo token
      originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
      return PrivateAxios.request(originalRequest)
    } catch (_error) {
      // Si el refresco falla, actualizar el estado de autenticación
      store.dispatch(noAuthenticated())
      store.dispatch(retryRefreshToken())
      return Promise.reject(_error?.message)
    }
  }

  // Para cualquier otro error, rechazar como está
  return Promise.reject(error)
})

// export async function lastBullet() {
//   try {
//     const refreshResponse = await axios.get(
//       `${process.env.REACT_APP_BACKEND_IP}/api/v1/auth/refresh-token`,
//       { withCredentials: true }
//     )

//     if (refreshResponse.status === 200) {
//       const newAccessToken = refreshResponse.data?.access_token

//       // Almacenamos el nuevo token de acceso y reintentamos la solicitud original.
//       //   localStorage.setItem('token', newAccessToken)
//       PrivateAxios.defaults.headers[
//         'Authorization'
//       ] = `Bearer ${newAccessToken}`
//     } else throw new Error('Token invalid')
//     // return PrivateAxios.request(error.config)
//   } catch (err) {
//     throw err?.message
//   }
// }
