import React from 'react'

import { DefaultRoutes } from '../constants/routes/routes'

// import Avatar from '../assets/icons/Analyst2.png'
// import Avatar from '../assets/icons/avatar.gif'
// import Avatar from '../assets/icons/User.svg'
import Navlink from './Navlink'
import ZeroAPT from '../assets/Ocelot/ZeroAPT-GreenWhite.svg'
import PowerByOcelot from '../assets/Ocelot/Poweredby-OCELOT-Dark.svg'
import CustomAccordion from './shared/CustomAccordion'

function Sidebar() {
  return (
    <div className="flex flex-col justify-between p-4 absolute w-full h-screen bg-darkmode  z-40 md:bg-inherit md:relative md:w-1/4 lg:W-1/5 ">
      <div className="flex flex-row items-center justify-center  mb-12  mt-10 md:mt-2  md:pt-4 min-h-[3.5rem]">
        <img
          className="mr-4 md:mr-4"
          src={ZeroAPT}
          loading="lazy"
          alt="ZeroAPT"
        />
      </div>
      <div className="sidebar flex flex-col h-full gap-2 overflow-auto">
        {/*<Navlink title="Dashboard" link={DefaultRoutes.private.DASHBOARD} />*/}
        <Navlink
          title="Customers"
          link={DefaultRoutes.private.catalog.CUSTOMER}
        />
        <Navlink title="Scenarios" link={DefaultRoutes.private.SCENARIO} />
        <CustomAccordion title="AI Engine" defaultOpen={1}>
          <Navlink
            title="Scenario x TTPs"
            link={DefaultRoutes.private.ia.TTPS}
          />
        </CustomAccordion>
        <CustomAccordion title="APT Stages" defaultOpen={1}>
          <Navlink
            title="Refreshers"
            link={DefaultRoutes.private.catalog.REFRESHERS}
          />
          <Navlink
            title="Downloaders"
            link={DefaultRoutes.private.catalog.DOWNLOADER}
          />
          <Navlink
            title="Rat loaders"
            link={DefaultRoutes.private.catalog.RAT_LOADER}
          />
          <hr />
          <Navlink title="Phishing" link={DefaultRoutes.private.PHISHING} />
          {/* <Navlink
              title="Initial delivery"
              link={DefaultRoutes.private.INITIAL_DELIVERY}
            /> */}
          <Navlink title="Endpoint" link={DefaultRoutes.private.ENDPOINT} />
          <Navlink
            title="Lateral movement"
            link={DefaultRoutes.private.LATERALMOVEMENT}
          />
          <Navlink
            title="Exfiltration"
            link={DefaultRoutes.private.EXFILTRATION}
          />
        </CustomAccordion>
        <CustomAccordion title="Testing tools" defaultOpen={1}>
          <Navlink
            title="Scenarios tester"
            link={DefaultRoutes.private.testing.SCENARIOS}
          />
          <Navlink
            title="Patchers tester"
            link={DefaultRoutes.private.testing.PATCHER}
          />
        </CustomAccordion>
        <CustomAccordion title="Catalogs" defaultOpen={1}>
          <Navlink title="Actors" link={DefaultRoutes.private.ACTOR} />

          <Navlink
            title="Control types"
            link={DefaultRoutes.private.catalog.CONTROL_TYPES}
          />
          <Navlink
            title="Control source"
            link={DefaultRoutes.private.catalog.CONTROL_SOURCE}
          />

          <Navlink
            title="Industries"
            link={DefaultRoutes.private.catalog.INDUSTRY}
          />
          <Navlink
            title="MITRE Techniques"
            link={DefaultRoutes.private.catalog.MITTRE_TECHNIQUES}
          />
          <Navlink
            title="Templates"
            link={DefaultRoutes.private.catalog.TEMPLATES}
          />
          <Navlink title="Users" link={DefaultRoutes.private.catalog.USER} />
        </CustomAccordion>
      </div>
      <div className="font-neue-machina group w-full text-xs md:text-sm py-2  m-auto ">
        <img
          loading="lazy"
          src={PowerByOcelot}
          alt="Power by OCELOT Team"
          className="h-16 mx-auto"
        />
      </div>
    </div>
  )
}

export default Sidebar
