import React, { Suspense } from 'react'

import { Outlet } from 'react-router-dom'
import { hideSidebar, showSidebar } from '../actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'

import Close from '../assets/icons/Close.svg'
import LoadingPage from './LoadingPage'
import Menu from '../assets/icons/Menu.svg'
import ProfileMenu from '../components/shared/ProfileMenu'
import Sidebar from '../components/Sidebar'

function MainPage() {
  const { sidebar } = useSelector((state) => state.ui)
  const dispatch = useDispatch()

  function handleSidebar(func) {
    dispatch(func())
  }

  return (
    <div className="flex flex-row h-screen bg-darkmode">
      {sidebar && <Sidebar />}
      <div className=" h-screen w-full overflow-auto bg-information-gradient">
        {/*  Navbar */}
        <div className="h-max flex items-center sticky top-0 z-40 bg-darkmode">
          {sidebar ? (
            <button
              className="mr-auto mx-4 rounded-full px-2 transition ease-in-out delay-100 sticky top-0 z-50 hover:scale-110 "
              onClick={() => handleSidebar(hideSidebar)}
            >
              <img loading="lazy" src={Close} alt="Close" />
            </button>
          ) : (
            <div className="px-4 py-2   transition ease-in-out delay-100 hover:scale-110">
              <button className="" onClick={() => handleSidebar(showSidebar)}>
                <img src={Menu} loading="lazy" alt="" className=" h-5 " />
              </button>
            </div>
          )}
          <div className="flex items-center ml-auto mr-2 md:mr-4">
            <ProfileMenu />
          </div>
        </div>

        <Suspense fallback={<LoadingPage />}>
          <div className="h-[calc(100vh-7vh)] bg-information-gradient">
            <Outlet />
          </div>
        </Suspense>
      </div>
    </div>
  )
}

export default MainPage
