import { useState } from 'react'

const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const reset = (dataValues = {}) => {
    if (Object.keys(dataValues).length === 0) setValues(initialState)
    else setValues(dataValues)
  }

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    })
  }

  const handleFileChange = (target) => {
    setValues({
      ...values,
      [target.name]: target.payload,
    })
  }

  return [values, handleInputChange, reset, handleFileChange]
}

export default useForm
