import React from 'react'
import LineChartComponent from './charts/LineChartComponent'

function Card() {
  return (
    <div className="bg-gray-dark rounded-xl max-w-lg flex-auto p-4 w-full md:w-1/4 hover:bg-gray-700 hover:shadow-2xl ease-in-out duration-300">
      <div className=" text-3xl font-semibold">Titulo</div>
      <div className="flex my-2">
        <div className="flex-1 break-words my-auto">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500sk
        </div>

        {/* <img className='w-1/2 md:w-1/3' src={logo} alt='Card img'/> */}
        <div className="w-1/2 my-auto">
          <LineChartComponent />
        </div>
      </div>
      <div className="text-sm">Footer</div>
    </div>
  )
}

export default Card
