import React from 'react'

import { useSelector } from 'react-redux'
import Login from '../pages/Login'
import { Navigate, useLocation } from 'react-router-dom'
import { DefaultRoutes } from '../constants/routes/routes'

function PublicGuard() {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const location = useLocation()

  if (isAuthenticated) {
    return (
      <Navigate
        to={DefaultRoutes.public.DEFAULT}
        replace
        state={{ from: location }}
      />
    )
  }

  return !isAuthenticated && <Login />
}

export default PublicGuard
