import { useState } from 'react'

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'

export default function CustomAccordion({ title, children, defaultOpen = 0 }) {
  const [open, setOpen] = useState(defaultOpen)

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value)
  }

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  }

  return (
    <>
      <Accordion
        className="text-white"
        open={open === 1}
        animate={customAnimation}
      >
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="text-lg font-medium text-white hover:text-white"
        >
          {title}
        </AccordionHeader>
        <AccordionBody>
          <div className="flex flex-col gap-2 text-base text-white">
            {children}
          </div>
        </AccordionBody>
      </Accordion>
    </>
  )
}
