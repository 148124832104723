import React from 'react'

import { hideSidebar } from '../actions/uiActions'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function Navlink({ title = 'Soy un titulo', link = 'dashboard' }) {
  const dispatch = useDispatch()

  const size = () => {
    if (window.screen.width <= 768) dispatch(hideSidebar())
  }

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? 'p-3 rounded-md bg-gray-dark transition-all  duration-300 ease-in-out'
          : 'p-3 rounded-md hover:bg-gray-dark transition duration-300 ease-in-out'
      }
      to={link}
      onClick={size}
    >
      {' '}
      {title}
    </NavLink>
  )
}

export default Navlink
