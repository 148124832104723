import 'react-toastify/dist/ReactToastify.css'
import './App.css'

import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from './store/store'
import { ToastContainer } from 'react-toastify'

import MainRouter from './routes/MainRouter'

import 'react18-json-view/src/style.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <div className="font-roboto text-white">
          <ToastContainer theme="colored" />
          <MainRouter />
        </div>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
