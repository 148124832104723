import React from 'react'

import { DefaultRoutes } from '../constants/routes/routes'
import { postAuth } from '../api/auth/Auth'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import logo_mbq from '../assets/mbq-logo.png'
import logo_ocelot from '../assets/Ocelot/OCELOT-dark.svg'
import ZeroAPT from '../assets/Ocelot/ZeroAPT-GreenWhite.svg'
import useForm from '../hooks/useForm'
import { isAuthenticated, login } from '../actions/authActions'
import { formatUsername, getTokenBody } from '../utils/utils'
import { useDispatch } from 'react-redux'

function Login() {
  const initState = {
    username: '',
    password: '',
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [values, handleInputChange, reset] = useForm(initState)

  const mutationPost = useMutation((data) => postAuth(data), {
    onSuccess: (resp) => {
      localStorage.setItem('token', resp.data?.access_token)
    },
  })

  const submit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('username', values.username)
    formData.append('password', values.password)

    mutationPost.mutate(formData, {
      onSuccess: () => {
        const { sub, scopes } = getTokenBody()
        const greet = formatUsername(sub)
        dispatch(isAuthenticated())
        dispatch(login({ username: greet, role: scopes[0] }))
        toast.success('Welcome back ' + greet)
        navigate(`..${DefaultRoutes.public.DEFAULT}`)
      },
      onError: () => {
        toast.error('Username or password wrong')
      },
      onSettled: () => {
        reset()
      },
    })
  }

  return (
    <div className=" font-neue-machina flex h-screen min-h-full bg-darkmode text-white items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-10">
        <div>
          <div className="flex flex-row items-center justify-center mt-10 md:mt-2  min-h-[3.5rem]">
            <img className="mr-4 md:mr-2 my-auto" src={ZeroAPT} alt="ZeroAPT" />
          </div>
          <h2 className="mt-2 text-center text-2xl font-medium tracking-tight text-white">
            Challenge Your Defenses, Safely
          </h2>
        </div>
        <form className=" space-y-6" onSubmit={(e) => submit(e)}>
          <h3 className="mt-6 text-center text-xl font-medium tracking-tight text-white/80">
            Sign in to your account
          </h3>
          <input type="hidden" name="remember" value="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="mb-6">
              <label className="sr-only">Email address</label>
              <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold my-2">
                Username
              </label>
              <input
                name="username"
                type="text"
                autoComplete="username"
                required
                value={values.username}
                onChange={handleInputChange}
                className="relative block w-full appearance-none bg-gray-700 rounded border border-gray-700 bg-neutral-800 p-3 text-white focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="Enter your username"
              />
            </div>
            <div>
              <label className="sr-only">Password</label>
              <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold my-2">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={values.password}
                onChange={handleInputChange}
                className="relative block w-full appearance-none bg-gray-700 rounded border border-gray-700 bg-neutral-800  p-3  text-white  focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="* * * * * * * *"
              />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="text-sm">
              <button className="font-medium text-white hover:text-gray-300">
                Forgot your password?
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-1/2 mx-auto justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white hover:bg-primary/80 hover:shadow-lg ease-in-out duration-300"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-green-700 group-hover:text-primary hover:shadow-lg ease-in-out duration-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
          <div className="flex flex-col-reverse md:flex-row border-0 border-t border-zinc-700 pt-6">
            <img
              className="mx-auto h-12 w-auto"
              loading="lazy"
              src={logo_mbq}
              alt="Your Company"
            />
            <img
              className="mx-auto h-12 w-auto"
              loading="lazy"
              src={logo_ocelot}
              alt="Your Company"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
