import React, { lazy } from 'react'
import { DefaultRoutes } from '../constants/routes/routes'
import { Route, Routes } from 'react-router-dom'

import Dashboard from '../pages/Dashboard'
import MainPage from '../pages/MainPage'
import NotFound from '../pages/NotFound'

const Actor = lazy(() => import('../pages/Actor'))
const AttackReportGrid = lazy(() => import('../pages/AttackReportGrid'))
const Client = lazy(() => import('../pages/Client'))
const ControlType = lazy(() => import('../pages/ControlType'))
const ControlSource = lazy(() => import('../pages/ControlSource'))
const Downloader = lazy(() => import('../pages/Downloader'))
const Refresher = lazy(() => import('../pages/Refresher'))
const Endpoint = lazy(() => import('../pages/Endpoint'))
const Exfiltration = lazy(() => import('../pages/Exfiltration'))
const Industry = lazy(() => import('../pages/Industry'))
const LateralMovement = lazy(() => import('../pages/LateralMovement'))
const Loader = lazy(() => import('../pages/Loader'))
const MittreTechniques = lazy(() => import('../pages/MittreTechniques'))
const PatcherTester = lazy(() => import('../pages/testing/PatcherTester'))
const Payloads = lazy(() => import('../pages/Payloads'))
const Phishing = lazy(() => import('../pages/Phishing'))
const PhishingExecution = lazy(() =>
  import('../pages/execution/PhishingExecution')
)
const ExecutionHandler = lazy(() =>
  import('../pages/execution/ExecutionHandler')
)
const PhishingSender = lazy(() => import('../pages/PhishingSender'))
const Scenario = lazy(() => import('../pages/Scenarios'))
const ScenariosTester = lazy(() => import('../pages/testing/ScenariosTester'))
const ScenarioTesterDetails = lazy(() =>
  import('../pages/testing/ScenarioTesterDetails')
)
const SimulationDetails = lazy(() => import('../pages/SimulationDetails'))
const SimulationMachine = lazy(() => import('../pages/SimulationMachine'))
const Simulations = lazy(() => import('../pages/Simulations'))
const Template = lazy(() => import('../pages/Template'))
const User = lazy(() => import('../pages/User'))
const Ttps = lazy(() => import('../pages/ia/Ttps'))

function PrivateRoutes() {
  return (
    <Routes>
      <Route path={`${DefaultRoutes.role.ANALYST}/`} element={<MainPage />}>
        <Route path={DefaultRoutes.private.DASHBOARD} element={<Dashboard />} />
        <Route
          path={DefaultRoutes.private.CUSTOMER_SIMULATION_ROUTER}
          element={<Simulations />}
        />
        <Route
          path={DefaultRoutes.private.SIMULATION_DETAILS_ROUTER}
          element={<SimulationDetails />}
        />
        <Route
          path={DefaultRoutes.private.SIMULATION_MACHINE_ROUTER}
          element={<SimulationMachine />}
        />
        <Route path={DefaultRoutes.private.SCENARIO} element={<Scenario />} />
        <Route path={DefaultRoutes.private.PAYLOAD} element={<Payloads />} />
        {/* apt-tecniquies */}
        <Route path={DefaultRoutes.private.PHISHING} element={<Phishing />} />
        <Route path={DefaultRoutes.private.ENDPOINT} element={<Endpoint />} />
        <Route
          path={DefaultRoutes.private.LATERALMOVEMENT}
          element={<LateralMovement />}
        />
        <Route
          path={DefaultRoutes.private.EXFILTRATION}
          element={<Exfiltration />}
        />
        {/* Testing tools */}
        <Route
          path={DefaultRoutes.private.testing.PATCHER}
          element={<PatcherTester />}
        />
        <Route
          path={DefaultRoutes.private.testing.SCENARIOS}
          element={<ScenariosTester />}
        />
        <Route
          path={DefaultRoutes.private.testing.SCENARIO_DETAILS_ROUTER}
          element={<ScenarioTesterDetails />}
        />

        {/* IA Features */}
        <Route path={DefaultRoutes.private.ia.TTPS} element={<Ttps />} />

        {/* CATALOG */}
        <Route
          path={DefaultRoutes.private.catalog.RAT_LOADER}
          element={<Loader />}
        />
        <Route
          path={DefaultRoutes.private.catalog.REFRESHERS}
          element={<Refresher />}
        />
        <Route
          path={DefaultRoutes.private.catalog.DOWNLOADER}
          element={<Downloader />}
        />
        <Route path={DefaultRoutes.private.ACTOR} element={<Actor />} />
        <Route
          path={DefaultRoutes.private.catalog.CUSTOMER}
          element={<Client />}
        />
        <Route
          path={DefaultRoutes.private.catalog.CONTROL_SOURCE}
          element={<ControlSource />}
        />
        <Route
          path={DefaultRoutes.private.catalog.CONTROL_TYPES}
          element={<ControlType />}
        />
        <Route
          path={DefaultRoutes.private.catalog.INDUSTRY}
          element={<Industry />}
        />
        <Route
          path={DefaultRoutes.private.catalog.TEMPLATES}
          element={<Template />}
        />
        <Route
          path={DefaultRoutes.private.catalog.MITTRE_TECHNIQUES}
          element={<MittreTechniques />}
        />
        <Route path={DefaultRoutes.private.catalog.USER} element={<User />} />
        {/* PHISHING SENDER */}
        <Route
          path={DefaultRoutes.private.PHISHING_SENDER_ROUTER}
          element={<PhishingSender />}
        />
        {/* BATUTA RED EXECUTION */}
        <Route
          path={DefaultRoutes.private.PHISHING_EXECUTION_ROUTER}
          element={<PhishingExecution />}
        />
        <Route
          path={DefaultRoutes.private.EXECUTION_DETAILS_ROUTER_PHASE}
          element={<ExecutionHandler />}
        />
        {/* ATTACK USE CASES */}
        <Route
          path={DefaultRoutes.private.VDI_DETAILS_ROUTER_PHASE}
          element={<AttackReportGrid />}
        />
        {/* NOT FOUND */}
        <Route path={DefaultRoutes.any} element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default PrivateRoutes
