import React from 'react'

function NotFound({
  content = '404',
  title = 'Page Not Found',
  message = 'It looks like the page you are looking for has been moved or does not exist.',
  top = 'top-32',
}) {
  return (
    <div className="font-neue-machina mt-16">
      <div
        className={`relative m-auto left-0 right-0 z-10 w-36 h-52 border-red-800 border-4 ${top} ghost`}
      >
        <span className="absolute left-0 right-0 bottom-10 m-auto text-center text-4xl font-semibold">
          {content}
        </span>
        {/* <!-- Eyes --> */}
        <div className="rounded-full absolute left-0 right-0 w-3 h-3 bg-red-800 mx-auto mt-10 transform translate-x-6"></div>
        <div className="rounded-full absolute left-0 right-0 w-3 h-3 bg-red-800 mx-auto mt-10 transform -translate-x-6"></div>

        {/* <!-- Mouth --> */}
        <div className="w-10 h-2 rounded-lg absolute left-0 right-0 mx-auto mt-20 bg-red-800"></div>

        {/* <!-- Tail --> */}
        <div className="corner border-red-800 border-4"></div>
        <div className="corner two border-red-800 border-4"></div>
        <div className="corner three border-red-800 border-4"></div>
        <div className="corner four border-red-800 border-4"></div>

        <div className="over"></div>
        <div className="over two"></div>
        <div className="over three"></div>
        <div className="over four"></div>

        {/* <!-- Shadow --> */}
        <div className="ghost-shadow w-32 h-10 mx-auto mt-64 rounded bg-[#450a0a] left-0 right-0 absolute"></div>
      </div>

      <div className="container mx-auto text-center mt-60">
        <h2 className="text-4xl font-bold mb-4">{title}</h2>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default NotFound
