import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { DefaultRoutes } from '../constants/routes/routes'

import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { checkAuth } from '../api/auth/Auth'
import { formatUsername, getTokenBody } from '../utils/utils'
import { login, isAuthenticated, logout } from '../actions/authActions'
import LoadingPage from '../pages/LoadingPage'
import PublicGuard from './PublicGuard'
import AuthGuard from './AuthGuard'

function MainRouter() {
  const dispatch = useDispatch()

  const { isLoading } = useQuery(['Login-resource'], checkAuth, {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: 0,
    onSuccess: () => {
      const { sub, scopes } = getTokenBody()
      dispatch(login({ username: formatUsername(sub), role: scopes[0] }))
      dispatch(isAuthenticated())
    },
    onError: () => {
      dispatch(logout())
      localStorage.removeItem('token')
    },
  })

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route
          path={DefaultRoutes.public.DEFAULT}
          element={
            // TODO Cambiar a vista predeterminada
            <Navigate
              to={`${DefaultRoutes.private.DEFAULT}${DefaultRoutes.role.ANALYST}/`}
            />
          }
        />
        {/* // TODO: Change this for PublicGuard  */}
        <Route path={DefaultRoutes.public.LOGIN} element={<PublicGuard />} />
        {/* Private Routes */}

        {/* // TODO: Change this for AuthGuard  */}
        <Route
          path={`${DefaultRoutes.private.DEFAULT}${DefaultRoutes.public.DEFAULT}${DefaultRoutes.any}`}
          element={<AuthGuard />}
        />
        <Route
          path={DefaultRoutes.any}
          element={
            // TODO Cambiar a vista predeterminada
            <Navigate to={`../${DefaultRoutes.public.DEFAULT}`} />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default MainRouter
