export const DefaultRoutes = {
  public: {
    LOGIN: 'login',
    DEFAULT: '/',
  },
  private: {
    DEFAULT: 'private/',
    DASHBOARD: 'dashboard',
    //* Router urls
    CUSTOMER_SIMULATION_ROUTER: 'customer/:customer_id',
    PHISHING_EXECUTION_ROUTER: 'phishing-execution/:phishing_execution_id',
    PHISHING_SENDER_ROUTER: 'phishing-sender/:phishing_sender_id',
    SIMULATION_DETAILS_ROUTER:
      'customer/:customer_id/simulation/:simulation_id',
    SIMULATION_MACHINE_ROUTER:
      'customer/:customer_id/simulation/:simulation_id/machine/:machine_id',
    VDI_DETAILS_ROUTER_PHASE:
      'customer/:customer_id/simulation/:simulation_id/machine/:machine_id/report/:phase',
    EXECUTION_DETAILS_ROUTER_PHASE:
      'customer/:customer_id/simulation/:simulation_id/machine/:machine_id/execution/:phase',
    //* Helpers
    CAMPAIGN_MACHINE: 'machine/',
    PAYLOAD: 'payload',
    PHISHING_EXECUTION: 'phishing-execution/',
    EXECUTION: 'execution/',
    PHISHING_SENDER: 'phishing-sender/',
    REPORT: 'report/',
    SCENARIO: 'scenario',
    SIMULATION_DETAILS: 'details/',
    SIMULATION: 'simulation/',
    //* Ocelot Catalog
    ACTOR: 'apt-techniques/actors',
    INITIAL_DELIVERY: 'apt-techniques/initial-delivery',
    PHISHING: 'apt-techniques/phishing',
    ENDPOINT: 'apt-techniques/endpoint',
    LATERALMOVEMENT: 'apt-/lateral-movement',
    EXFILTRATION: 'apt-techniques/exfiltration',
    //* Catalog
    ia: {
      TTPS: 'ia/scenarios-ttps',
      SCENARIOS: 'ia/scenarios',
    },
    catalog: {
      CUSTOMERS: 'customers/',
      CUSTOMER: 'customer/',
      CONTROL_SOURCE: 'catalog/control-source',
      CONTROL_TYPES: 'catalog/control-types',
      REFRESHERS: 'catalog/refresher',
      DOWNLOADER: 'catalog/downloader',
      IOA: 'catalog/ioa',
      IOC: 'catalog/ioc',
      INDUSTRY: 'catalog/industry',
      MITTRE_TECHNIQUES: 'catalog/mittre-techniques',
      RAT_LOADER: 'catalog/rat-loader',
      TEMPLATES: 'catalog/templates',
      USER: 'catalog/users',
    },
    testing: {
      SCENARIOS: 'testing/scenarios',
      SCENARIO_DETAILS: 'testing/scenarios/details/',
      SCENARIO_DETAILS_ROUTER: 'testing/scenarios/details/:scenario_id',
      PATCHER: 'testing/patchers',
    },
  },
  role: {
    ANALYST: 'analyst',
    ADMIN: 'admin',
    GUEST: 'guest',
  },
  any: '*',
}
