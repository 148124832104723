// import { types } from "../types/types";

import { types } from '../constants/types/types'

const initState = {
  sidebar: true,

  form: { invalid: false, selectedRow: {} },
  campaign: {
    selectedRow: {},
    selectedScenario: [],
    selectedControlSource: {},
    selectedMachine: {},
    selectedJob: {},
    multiJobs: [],
  },
  phishingCampaign: { selectedPhishingCampaign: {}, phishingDetails: {} },
  scenario: { selectedRow: {}, tesingScenario: {} },
  campaignReport: { selectedRecord: {}, viewRecord: {} },
  catalog: {
    selectedUser: {},
    selectedTemplate: {},
    selectedClient: {},
    selectedControlType: {},
    selectedIOA: {},
    selectedIOC: {},
    selectedIndustry: {},
    selectedMittreTechnique: {},
  },
  execution: {
    selectedPhishing: {},
  },
  loading: true,
}

export const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case types.openSidebar:
      return {
        ...state,
        sidebar: true,
      }
    case types.closeSidebar:
      return {
        ...state,
        sidebar: false,
      }
    case types.invalidForm:
      return {
        ...state,
        form: {
          ...state.form,
          invalid: true,
        },
      }
    case types.validForm:
      return {
        ...state,
        form: {
          ...state.form,
          invalid: false,
        },
      }
    //* SELECTED
    case types.selectedRow:
      return {
        ...state,
        form: {
          ...state.form,
          selectedRow: action.payload,
        },
      }
    case types.selectedPhishingSenderDetails:
      return {
        ...state,
        phishingCampaign: {
          ...state.phishingCampaign,
          phishingDetails: action.payload,
        },
      }
    case types.selectedPhishingCampaign:
      return {
        ...state,
        phishingCampaign: {
          ...state.phishingCampaign,
          selectedPhishingCampaign: action.payload,
        },
      }
    case types.selectedCampaign:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedRow: action.payload,
        },
      }
    case types.selectedCampaignControlSource:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedControlSource: action.payload,
        },
      }
    case types.selectedCampaignScenario:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedScenario: action.payload,
        },
      }
    case types.selectedCampaignReport:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          selectedReport: action.payload,
        },
      }
    case types.selectedCampaignRecord:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          selectedRecord: action.payload,
        },
      }
    case types.selectedRecordView:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          viewRecord: action.payload,
        },
      }
    // * * SCENARIO
    case types.selectedScenario:
      return {
        ...state,
        scenario: {
          ...state.scenario,
          selectedRow: action.payload,
        },
      }
    case types.selectedScenarioTester:
      return {
        ...state,
        scenario: {
          ...state.scenario,
          tesingScenario: action.payload,
        },
      }
    case types.selectedMachine:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedMachine: action.payload,
        },
      }
    //* Execution
    case types.selectedPhishingExecution:
      return {
        ...state,
        execution: {
          ...state.execution,
          selectedPhishing: action.payload,
        },
      }
    case types.selectedJob:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedJob: action.payload,
        },
      }
    case types.selectedMultiJobs:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          multiJobs: action.payload,
        },
      }
    // * * CATALOG
    case types.selectedUser:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedUser: action.payload,
        },
      }
    case types.selectedClient:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedClient: action.payload,
        },
      }
    case types.selectedControlType:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedControlType: action.payload,
        },
      }
    case types.selectedIOA:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIOA: action.payload,
        },
      }
    case types.selectedIOC:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIOC: action.payload,
        },
      }
    case types.selectedIndustry:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIndustry: action.payload,
        },
      }
    case types.selectedMittreTechnique:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedMittreTechnique: action.payload,
        },
      }
    case types.selectedTemplate:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedTemplate: action.payload,
        },
      }
    //* CLEAN
    case types.cleanCampaignRecord:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          selectedRecord: {},
        },
      }
    case types.cleanRecordView:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          viewRecord: {},
        },
      }
    case types.cleanRow:
      return {
        ...state,
        form: {
          ...state.form,
          selectedRow: {},
        },
      }
    case types.cleanPhishingCampaign:
      return {
        ...state,
        phishingCampaign: {
          ...state.phishingCampaign,
          selectedPhishingCampaign: {},
        },
      }
    case types.cleanPhishingSenderDetails:
      return {
        ...state,
        phishingCampaign: {
          ...state.phishingCampaign,
          phishingDetails: {},
        },
      }
    case types.cleanCampaignRow:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedRow: {},
          selectedScenario: [],
        },
      }
    case types.cleanCampaignControlSourceRow:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedControlSource: {},
        },
      }
    case types.cleanCampaignScenario:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedScenario: [],
        },
      }
    case types.cleanCampaignReport:
      return {
        ...state,
        campaignReport: {
          ...state.campaignReport,
          selectedReport: {},
        },
      }
    // * * SCENARIO
    case types.cleanScenarioRow:
      return {
        ...state,
        scenario: {
          ...state.scenario,
          selectedRow: {},
        },
      }
    case types.cleanScenarioTester:
      return {
        ...state,
        scenario: {
          ...state.scenario,
          tesingScenario: {},
        },
      }
    case types.cleanMachine:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedMachine: {},
        },
      }
    //* EXECUTION
    case types.cleanPhishingExecution:
      return {
        ...state,
        execution: {
          ...state.execution,
          selectedPhishing: {},
        },
      }
    case types.cleanJob:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          selectedJob: {},
        },
      }
    case types.cleanMultiJobs:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          multiJobs: [],
        },
      }
    // * * CATALOG
    case types.cleanUser:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedUser: {},
        },
      }
    case types.cleanClient:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedClient: {},
        },
      }
    case types.cleanControlType:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedControlType: {},
        },
      }
    case types.cleanIOA:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIOA: {},
        },
      }
    case types.cleanIndustry:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIndustry: {},
        },
      }
    case types.cleanIOC:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedIOC: {},
        },
      }
    case types.cleanMittreTechnique:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedMittreTechnique: {},
        },
      }
    case types.cleanTemplate:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          selectedTemplate: {},
        },
      }

    case types.loading:
      return {
        ...state,
        loading: true,
      }
    case types.loaded:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
