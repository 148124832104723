import React from 'react'
import {
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from '@material-tailwind/react'
import {
  UserCircleIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  PowerIcon,
} from '@heroicons/react/24/outline'
import ProfileLogo from '../../assets/icons/Hacker_logo.svg'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { logoutAuth } from '../../api/auth/Auth'
import { useDispatch, useSelector } from 'react-redux'
// import { PrivateAxios } from '../../api/auth/PrivateAxios'
import { DefaultRoutes } from '../../constants/routes/routes'
import { noAuthenticated } from '../../actions/authActions'
import { PrivateAxios } from '../../api/auth/PrivateAxios'

export default function ProfileMenu() {
  const { username, role } = useSelector((state) => state.auth)
  // const { username, role } = { username: 'Batuta Scanner', role: 'ANALYST' }
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { refetch } = useQuery(['Logout'], logoutAuth, {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    enabled: false,
    retry: 0,
    onSuccess: () => {
      dispatch(noAuthenticated())
    },
  })

  function handleLogout() {
    PrivateAxios.defaults.headers.common = {}
    refetch()
    navigate(`../../${DefaultRoutes.public.LOGIN}`, { replace: true })
    closeMenu()
  }

  function greet() {
    alert('🌊')
    closeMenu()
  }
  const closeMenu = () => setIsMenuOpen(false)

  // profile menu component
  const profileMenuItems = [
    {
      label: 'My Profile',
      icon: UserCircleIcon,
      func: greet,
    },
    {
      label: 'Edit Profile',
      icon: Cog6ToothIcon,
      func: greet,
    },
    //   {
    //     label: 'Inbox',
    //     icon: InboxArrowDownIcon,
    //   },
    //   {
    //     label: 'Help',
    //     icon: LifebuoyIcon,
    //   },
    {
      label: 'Sign Out',
      icon: PowerIcon,
      func: handleLogout,
    },
  ]

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          className="title flex items-center text-white gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            alt="Profile img"
            className="min-h-[5vh] md:h-[5vh] rounded-full border-2 border-red-500"
            src={ProfileLogo}
          />
          <div className="flex flex-col justify-center items-center w-full">
            <span className="text-xs font-normal">{username}</span>
            <span className="text-gray-300 text-xs font-light">{role}</span>
          </div>
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-6 w-6 transition-transform ${
              isMenuOpen ? 'rotate-180' : ''
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1 text-white bg-gray-dark border-gray-900 shadow-xl z-40">
        {profileMenuItems.map(({ label, icon, func }, key) => {
          const isLastItem = key === profileMenuItems.length - 1
          return (
            <MenuItem
              key={label}
              onClick={func}
              className={`flex items-center gap-2 py-1.5 rounded ${
                isLastItem
                  ? 'hover:bg-red-500/20 focus:bg-red-500/20 active:bg-red-500/20'
                  : 'hover:bg-gray-700'
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? 'text-red-500' : ''}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? 'red' : 'inherit'}
              >
                {label}
              </Typography>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
